import { createTheme } from "@mui/material/styles";
import { colors } from "../styles/variable";

const AppTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: colors.secondary,
      dark: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.lightWhite,
      light: colors.lightWhite,
      dark: colors.lightWhite,
      contrastText: colors.lightWhite,
    },
  },
  typography: {
    fontFamily: "BasisGrotesque",
    fontWeight: "600",
    h5: {
      fontSize: `24px !important`,
      fontWeight: "600",
      color: colors.primaryText,
    },
    h3: {
      fontSize: "18px",
      fontWeight: "600",
      color: colors.primaryText,
    },
    h4: {
      fontWeight: "400",
      fontSize: "17px",
      letterSpacing: "0.00835em",
      lineHeight: "1.5",
    },
    h1: {
      fontSize: "16px",
      color: colors.textButton,
      fontWeight: "600",
    },
    h6: {
      fontSize: "18px",
      color: colors.textButton,
      fontWeight: "600",
    },
    h2: {
      fontSize: "18px",
      fontWeight: "600",
      color: colors.secondaryHeaderTextColor,
    },
    subtitle1: {
      fontSize: "14px",
      color: colors.lightBlack,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "BasisGrotesque";
          src: local("BasisGrotesque"),
           url("./assets/fonts/BasisGrotesqueArabic/BasisGrotesqueArabicPro-Regular.ttf") format("truetype");
        }
      `,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: `4px 4px 16px ${colors.secondaryBoxShadowColor}`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: colors.white,
            borderRadius: "15px",
          },
          "& .MuiInputBase-root.Mui-focused": {
            backgroundColor: colors.white,
          },
          "& .MuiInputBase-root:hover": {
            backgroundColor: colors.white,
            "@media (hover: none)": {
              backgroundColor: colors.white
            }
          },
          "& .css-1vi9oct-MuiInputBase-input-MuiFilledInput-input": {
            height: "1.5rem",
          },
          "& label.Mui-focused": {
            color: colors.secondaryText,
          },
          "& .MuiInputLabel-root.Mui-error": {
            color: colors.secondaryText,
          },
          "& .MuiFilledInput-root": {
            backgroundColor: colors.white
          },
          "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: colors.white
          },
          "& .MuiFilledInput-root:hover": {
            backgroundColor: colors.white,
            "@media (hover: none)": {
              backgroundColor: colors.white
            }
          },
        },
        border: "none",
        outline: "0px",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "2px 2px 4px 0 rgba(179, 128, 252, 0.5)",
          letterSpacing: ".63px",
          textTransform: "inherit",
          fontSize: "18px",
          textOverflow: "ellipsis !important",
          whiteSpace: "nowrap !important",
          overflow: "hidden !important",
          display: "block !important",
          alignItems: "center",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
        },
      },
    },

  },
});

export default AppTheme;
