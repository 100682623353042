import CircularProgress from "@mui/material/CircularProgress";
import { loaderContainer, LoaderWrapper } from "./LoaderCSS";
import { Box } from "@mui/system";

const Loader = () => {
  return (
    <LoaderWrapper>
      <Box sx={loaderContainer}>
        <CircularProgress />
      </Box>
    </LoaderWrapper>
  );
};

export default Loader;
