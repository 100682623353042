import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    // Defining Poppins Fonts
    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Black.ttf) format('truetype');
        font-weight: 800;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-BlackItalic.ttf) format('truetype');
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Bold.ttf) format('truetype');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-BoldItalic.ttf) format('truetype');
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-ExtraBold.ttf) format('truetype');
        font-weight: 650;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf) format('truetype');
        font-weight: 650;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-ExtraLight.ttf) format('truetype');
        font-weight: 250;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-ExtraLightItalic.ttf) format('truetype');
        font-weight: 250;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Italic.ttf) format('truetype');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Light.ttf) format('truetype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-LightItalic.ttf) format('truetype');
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Medium.ttf) format('truetype');
        font-weight: 450;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-MediumItalic.ttf) format('truetype');
        font-weight: 450;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Regular.ttf) format('truetype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-SemiBold.ttf) format('truetype');
        font-weight: 650;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-SemiBoldItalic.ttf) format('truetype');
        font-weight: 650;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-Thin.ttf) format('truetype');
        font-weight: 200;
    }

    @font-face {
        font-family: 'Poppins';
        src: local('Poppins'), url(/assets/fonts/poppins/Poppins-ThinItalic.ttf) format('truetype');
        font-weight: 200;
        font-style: italic;
    }


    // Defining Gordita Fonts
    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Black.otf) format('opentype');
        font-weight: 800;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Black-Italic.otf) format('opentype');
        font-weight: 800;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Bold.otf) format('opentype');
        font-weight: 600;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Bold-Italic.otf) format('opentype');
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Regular-Italic.otf) format('opentype');
        font-weight: normal;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Light.otf) format('opentype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Light-Italic.otf) format('opentype');
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Medium.otf) format('opentype');
        font-weight: 450;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Medium-Italic.otf) format('opentype');
        font-weight: 450;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Regular.otf) format('opentype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Thin.otf) format('opentype');
        font-weight: 200;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Thin-Italic.otf) format('opentype');
        font-weight: 200;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Ultra-Italic.otf) format('opentype');
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: 'Gordita';
        src: local('Gordita'), url(/assets/fonts/gordita/Gordita-Ultra.otf) format('opentype');
        font-weight: 700;
    }
    
    html {
        box-sizing: border-box;
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        height: 100vh;
        font-size: 18px;
        overflow: hidden;
        margin: 0;
        font-family: Poppins, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    ::ng-deep .mat-form-field.mat-focused .mat-form-field-underline {
        display: none;
    }

    .MuiFormControlLabel-label.Mui-disabled {
        color: #ffffff !important;
    }
`;

export default GlobalStyles;
