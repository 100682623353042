import React, { lazy, Suspense } from "react";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import GlobalStyles from "./GlobalStyles";
import { LoaderContextProvider } from "./context/LoaderContext";
import AppTheme from "./theme/AppTheme";
import Loader from "./components/Loader/Loader";
import { createRoot } from "react-dom/client";
import "./index.css";

const App = lazy(() => import("./App").then(({ App }) => ({ default: App })));
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ThemeProvider theme={AppTheme}>
    <GlobalStyles />
    <Suspense fallback={<Loader />}>
      <LoaderContextProvider>
        <App />
      </LoaderContextProvider>
    </Suspense>
  </ThemeProvider>
);

reportWebVitals();
