import React from "react";

interface ILoader {
  loading: boolean;
  setLoading: Function;
  showMenu: boolean;
  setShowMenu: Function;
}

const LoaderContext = React.createContext<ILoader>({} as ILoader);

export const LoaderContextProvider: React.FC = ({ children }): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);

  const value = {
    setLoading: setLoading,
    loading,
    showMenu,
    setShowMenu,
  };
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

export function useLoaderContext() {
  return React.useContext(LoaderContext);
}
