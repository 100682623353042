export type WhitelabelColors = {
  bgColor: string;
  primary: string;
  secondaryHeaderTextColor: string;
  secondaryBoxShadowColor: string;
  lightWhite: string;
  lightBlack: string;
  white: string;
  black: string;
  transparentBlack: string;
  primaryText: string;
  secondaryText: string;
  textButton: string;
  positiveTextColor: string;
  negativeTextColor: string;
  invalidTextColor: string;
  positiveBgColor: string;
  negativeBgColor: string;
  invalidBgColor: string;
  disableCheckBoxColor: string;
  timerElapseColor: string;
  hoverEffectColor: string;
};
let colors: WhitelabelColors = require("./default.json");
let verticalLogo: any;
let horizontalLogo: any;
let testImage: any;
let branding = process.env.REACT_APP_BRANDING || "default";

if (branding !== undefined) {
  colors = require(`./${branding}.json`);
  verticalLogo = require(`../assets/images/${branding}/verticalLogo.svg`);
  horizontalLogo = require(`../assets/images/${branding}/horizontalLogo.svg`);
  testImage = require(`../assets/images/${branding}/test-image.jpg`);
}

export { colors, verticalLogo, horizontalLogo, testImage };
