import styled from "styled-components";
import { withTheme } from "@mui/styles";
import { colors } from "../../styles/variable";

export const LoaderWrapper = withTheme(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.01);
`);

export const loaderContainer = {
  top: 0,
  left: 0,
  position: "fixed",
  bottom: 0,
  right: 0,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  backgroundColor: colors.transparentBlack,
  zIndex: 2000,
  height: "100%",
};
